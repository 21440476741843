import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// Retrieve the initial state from a global variable injected into the HTML
const initialState = (window as any).__INITIAL_STATE__ || {};

ReactDOM.hydrateRoot(
  document.getElementById("root") as HTMLElement,
  <BrowserRouter>
    <App isSignedInCookie={initialState.isSignedInCookie} />
  </BrowserRouter>
);
