import { HomeOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TOOLTIPS_DELAY } from "../../constants";
import { PATHS } from "../../paths";

export const HomeNav = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToHome = useCallback(async () => {
    navigate(PATHS["home"].path);
  }, [navigate]);

  return (
    <Tooltip title={`${t("nav.home")}`} enterDelay={TOOLTIPS_DELAY}>
      <IconButton
        sx={{
          color: "primary.contrastText",
          bgcolor: "primary.main",
          "&:hover": { bgcolor: "primary.dark" },
        }}
        onClick={goToHome}
      >
        <HomeOutlined />
      </IconButton>
    </Tooltip>
  );
};
