import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PATHS } from "../paths";
import { shouldBeSignedInState, userState } from "../state/auth.state";
import { HomePage } from "./HomePage";
import Loading from "./loading/Loading";

export const RootPage = () => {
  const shouldBeSignedIn = useRecoilValue(shouldBeSignedInState);
  if (shouldBeSignedIn) {
    return <RootPageAuth />;
  }
  return <HomePage />;
};

const RootPageAuth = () => {
  const navigate = useNavigate();

  const user = useRecoilValue(userState);
  const userId = user?.id;

  useEffect(() => {
    if (userId) {
      navigate(PATHS["user_adventures"].constructor(userId));
    }
  }, [user]);

  if (user === undefined || user) {
    return <Loading />;
  }

  // Note: In theory this is never reached if the cookies are valid
  return <HomePage />;
};
