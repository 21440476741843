import { useEffect, useState } from "react";

const BREAK1 = "820px";

export const useIsMinimized = () => {
  const [break1, setBreak1] = useState(false);

  useEffect(() => {
    // Check if the code is running in a browser environment
    if (typeof window === "undefined") return;

    const mediaQueryList = window.matchMedia(`(min-width: ${BREAK1})`);
    const event = (e: MediaQueryListEvent) => setBreak1(e.matches);

    // Set the initial state based on the current window size
    setBreak1(mediaQueryList.matches);

    // Add the event listener
    mediaQueryList.addEventListener("change", event);

    // Cleanup the event listener on unmount
    return () => {
      mediaQueryList.removeEventListener("change", event);
    };
  }, []);

  return !break1;
};
