export const PATHS = {
  root: { path: "/", sitemap: { changefreq: "monthly", priority: 1.0 } },
  home: { path: "/home", sitemap: { changefreq: "monthly", priority: 1.0 } },
  signin: { path: "/signin" },
  signup: { path: "/signup" },
  passwordreset: { path: "/passwordreset" },
  user_adventures: {
    path: "/user/:user_id/adventures",
    constructor: (userId) => `/user/${userId}/adventures`,
  },
  adventure: {
    path: "/adventure/:adventure_id",
    constructor: (adventureId) => `/adventure/${adventureId}`,
  },
  debug: { path: "/debug" },
  regions: { path: "/regions" },
  empty: { path: "/map" },
};
