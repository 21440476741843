import { ArrowDownwardOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PATHS } from "../paths";
import { userState } from "../state/auth.state";
import { OptimizedImage } from "./image/OptimizedImage";
import { ContextualNav } from "./nav/ContextualNav";

export const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const contentRef = useRef<any>(null);

  const user = useRecoilValue(userState);
  const userId = user?.id;

  // Ensures hydration-safe rendering
  const [hydrated, setHydrated] = useState(false);
  useEffect(() => {
    setHydrated(true);
  }, []);

  const goToUserAdventures = useCallback(async () => {
    navigate(PATHS["user_adventures"].constructor(userId));
  }, [userId, navigate]);

  const handleSignIn = useCallback(async () => {
    navigate(PATHS["signin"].path);
  }, [navigate]);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <Helmet>
        <title>Kaminow</title>
        <meta
          name="description"
          content="Plan your next biking or hiking adventure with Kaminow!"
        />
      </Helmet>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
        }}
      >
        <Box
          sx={{
            zIndex: 1,
            display: "flex",
            width: "100%",
            position: "absolute",
            top: { xs: "16%", sm: "20%" },
            justifyContent: "center",
            gap: { xs: "10px", sm: "10px", md: "60px" },
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <Typography
              sx={{
                color: "white",
                textShadow: "1px 1px 6px rgba(0,0,0,0.2)",
                fontSize: 60,
              }}
            >
              Kaminow
            </Typography>
            <Typography
              align="center"
              sx={{
                color: "white",
                textShadow: "1px 1px 6px rgba(0,0,0,0.2)",
                fontSize: 26,
              }}
            >
              {t("home.app-text")}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "8px",
              flexDirection: "column",
              paddingTop: "15px",
              margin: "10px",
            }}
          >
            {hydrated && userId ? (
              <Button
                variant="contained"
                color="primary"
                onClick={goToUserAdventures}
              >
                {`${t("home.my-adventures")}`}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSignIn}
              >
                {`${t("home.sign-in")}`}
              </Button>
            )}

            <Button
              variant="contained"
              color="secondary"
              startIcon={<ArrowDownwardOutlined />}
              onClick={() =>
                contentRef.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              {`${t("home.learn-more")}`}
            </Button>
          </Box>
        </Box>
        <ContextualNav />
        <OptimizedImage
          src="home-bg.jpeg"
          ratio={3 / 2}
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
          alt="A snowy mountain landscape with a distant peak, evergreen trees, and two people hiking up a slope under a blue sky."
        />
      </Box>
      <Box
        ref={contentRef}
        sx={{
          display: "flex",
        }}
      >
        MORE SOON
      </Box>
    </div>
  );
};
